import { default as expiredFyxfD1cxf2Meta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/auth/expired.vue?macro=true";
import { default as indexphbDiTWFJrMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/auth/index.vue?macro=true";
import { default as sessionwwI2hRkaJfMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/auth/session.vue?macro=true";
import { default as addyEm0sCbMmmMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/add.vue?macro=true";
import { default as indexR6UoGEE5ZPMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/index.vue?macro=true";
import { default as edit3FRZDAoBW1Meta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/lookup/[customerId]/edit.vue?macro=true";
import { default as index1f3bSDdXpkMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/lookup/[customerId]/index.vue?macro=true";
import { default as customerskeKuXI50LeMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers.vue?macro=true";
import { default as addexCJt7k9IrMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings/add.vue?macro=true";
import { default as indexz20GLTFsFWMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings/index.vue?macro=true";
import { default as _91eventId_93oUdQHX01i6Meta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings/lookup/[eventId].vue?macro=true";
import { default as findingslZu5exrGEzMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings.vue?macro=true";
import { default as indexaxCSpZJM6HMeta } from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/index.vue?macro=true";
export default [
  {
    name: "auth-expired",
    path: "/auth/expired",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/auth/expired.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-session",
    path: "/auth/session",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/auth/session.vue").then(m => m.default || m)
  },
  {
    name: customerskeKuXI50LeMeta?.name,
    path: "/customers",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers.vue").then(m => m.default || m),
    children: [
  {
    name: "customers-add",
    path: "add",
    meta: addyEm0sCbMmmMeta || {},
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/add.vue").then(m => m.default || m)
  },
  {
    name: "customers",
    path: "",
    meta: indexR6UoGEE5ZPMeta || {},
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/index.vue").then(m => m.default || m)
  },
  {
    name: "customers-lookup-customerId-edit",
    path: "lookup/:customerId()/edit",
    meta: edit3FRZDAoBW1Meta || {},
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/lookup/[customerId]/edit.vue").then(m => m.default || m)
  },
  {
    name: "customers-lookup-customerId",
    path: "lookup/:customerId()",
    meta: index1f3bSDdXpkMeta || {},
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/customers/lookup/[customerId]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: findingslZu5exrGEzMeta?.name,
    path: "/findings",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings.vue").then(m => m.default || m),
    children: [
  {
    name: "findings-add",
    path: "add",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings/add.vue").then(m => m.default || m)
  },
  {
    name: "findings",
    path: "",
    meta: indexz20GLTFsFWMeta || {},
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings/index.vue").then(m => m.default || m)
  },
  {
    name: "findings-lookup-eventId",
    path: "lookup/:eventId()",
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/findings/lookup/[eventId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexaxCSpZJM6HMeta || {},
    component: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/pages/index.vue").then(m => m.default || m)
  }
]