<template>
  <NuxtRouteAnnouncer />
  <NuxtLoadingIndicator
    :height="20"
    class="animate-infinite animate-ease-in-out animate-alternate animate-fill-both animate-duration-500 animate-bounce"
  />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>
import { useHeadSafe, useId } from "#imports";
import { provideUseId } from "@headlessui/vue";
provideUseId(() => useId());
useHeadSafe({
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - FMA Administration`
      : "FMA Administration";
  },
});
</script>
<style>
html,
body {
  @apply bg-slate-100;
}
</style>
