import revive_payload_client_Cb9wHsUqGn from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BqCBdvicDM from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Iq9ncU8Dbz from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yu0DiVyrVk from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.12_sass@1.7_t5uppkbot4z2mtfio2l2aaq3pa/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_N41HzJUhsd from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_swQedVEEvB from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ub5qgbwZuu from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_E63RoZVU0Y from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_g2oHxJ6Igk from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.1_typescript@5.5.4_vue@3.4.34_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_z4one5Yk0J from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_LnJD4elL12 from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.34.1_nodemailer@6.9.14__magicast@0.3.4_nuxt@3.12._fpqptioulfed3wzad2yuoonrom/node_modules/@hebilicious/authjs-nuxt/dist/runtime/plugin.mjs";
import titles_KLeE5Ms71n from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.1_zzrmnhjtvou5b3wscgq4lghtgi/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_SzeiEpQFYC from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@nuxtjs+seo@2.0.0-rc.16_h3@1.12.0_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.1_zzrmnhjtvou5b3wscgq4lghtgi/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_0nenELgZh2 from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.12_sass@_wfubkhdcsqf5bwocv7zs3jkbam/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_4MkybLHs81 from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt-seo-experiments@4.0.0_magicast@0.3.4_rollup@4.18.1_vite@5.3.4_@types+node@20.14.12_sass@_wfubkhdcsqf5bwocv7zs3jkbam/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import vue_dompurify_html_iUg4nSnsga from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/packages/frontend/app/plugins/vue-dompurify-html.ts";
export default [
  revive_payload_client_Cb9wHsUqGn,
  unhead_BqCBdvicDM,
  router_Iq9ncU8Dbz,
  _0_siteConfig_yu0DiVyrVk,
  payload_client_N41HzJUhsd,
  navigation_repaint_client_swQedVEEvB,
  check_outdated_build_client_ub5qgbwZuu,
  chunk_reload_client_E63RoZVU0Y,
  plugin_vue3_g2oHxJ6Igk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_z4one5Yk0J,
  plugin_LnJD4elL12,
  titles_KLeE5Ms71n,
  defaults_SzeiEpQFYC,
  siteConfig_0nenELgZh2,
  inferSeoMetaPlugin_4MkybLHs81,
  vue_dompurify_html_iUg4nSnsga
]