import validate from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/pages/runtime/validate.js";
import client_45auth from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.34.1_nodemailer@6.9.14__magicast@0.3.4_nuxt@3.12._fpqptioulfed3wzad2yuoonrom/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/client-auth.mjs";
import manifest_45route_45rule from "/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.12_encoding@0.1.13_eslint@9.7.0_ioredis@5_yg63xwqnwn7rzgknwlefthco7e/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  client_45auth,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.34.1_nodemailer@6.9.14__magicast@0.3.4_nuxt@3.12._fpqptioulfed3wzad2yuoonrom/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/auth.mjs"),
  "guest-only": () => import("/Users/cy-gk/Projects/Cybercraft/fma-finding-bridge/node_modules/.pnpm/@hebilicious+authjs-nuxt@0.3.5_@auth+core@0.34.1_nodemailer@6.9.14__magicast@0.3.4_nuxt@3.12._fpqptioulfed3wzad2yuoonrom/node_modules/@hebilicious/authjs-nuxt/dist/runtime/middleware/guest-only.mjs")
}